export enum NavIds {
  tutorial = "Tutorial",
  accessPools = "accessPools",
  guide = "guide",
  aboutUs = "aboutUs",
}

export const NavData: { id: NavIds; title: string; link?: string }[] = [
  {
    id: NavIds.accessPools,
    title: "Cells",
  },
  {
    id: NavIds.tutorial,
    title: "Tutorial",
    link: "https://youtu.be/L4wUwfQexes",
  },
  {
    id: NavIds.guide,
    title: "Guide",
    link: "https://docs.google.com/document/d/1SdWruKv_Q1YnvP7HwO1Pfx9TcEfbHoGFOsXkeBn6uPM/edit?usp=sharing",
  },
  {
    id: NavIds.aboutUs,
    title: "About Us",
  },
];
