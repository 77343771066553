import { Colors } from "./styled";

const white = "#FFFFFF";
const black = "#000000";

export function colors(): Colors {
  return {
    // base
    white,
    black,

    // text
    text1: "#FFFFFF",
    text2: "#C3C5CB",
    text3: "#6C7284",
    text4: "#565A69",
    text5: "#2C2F36",

    // backgrounds / greys
    bg1: "#0D0D0D",
    bg2: "#303236",
    bg3: "#3a3d47",
    bg4: "#4c4f5c",
    bg5: "#6C7284",

    // specialty colors
    modalBG: "rgba(0,0,0,.425)",
    advancedBG: "rgba(0,0,0,0.1)",

    // primary colors
    primary1: "#F9C56C",
    primary2: "#F59E0C",
    primary3: "#935F06",
    primary4: "#C87900",
    primary5: "#9C5500",

    // color text
    primaryText1: "#fff",

    // secondary colors
    secondary1: "#B6A000",
    secondary2: "#007461",
    secondary3: "#1E9225",
    secondary4: "#303236",

    // other
    red1: "#FD4040",
    red2: "#F82D3A",
    red3: "#D60000",
    green1: "#27AE60",
    yellow1: "#FFE270",
    yellow2: "#F3841E",
    blue1: "#3B6A9C",
  };
}
