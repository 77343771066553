import { Box, IconButton, Link, useMediaQuery } from "@mui/material";
import theme from "@styles/theme";
import { ReactComponent as Logo } from "@assets/svg/logoBlack.svg";
import { NavData } from "@constants/NavIds";
import SocialNetworksLinks from "@constants/SocialNetworksLinks";
import { ReactComponent as TGLogo } from "@assets/svg/tgBlack.svg";
import { ReactComponent as XLogo } from "@assets/svg/xBlack.svg";
import { ReactComponent as WebLogo } from "@assets/svg/webBlack.svg";

const classes = {
  root: {
    width: "100%",
    height: 150,
    marginTop: 30,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.text.secondary,
  },
  logoContainer: {
    width: 50,
    height: 50,
  },
  navContainer: {
    display: "flex",
    alignItems: "center",
    gap: 3,
  },
  iconsContainer: {
    display: "flex",
    gap: 2,
  },
};

const MainFooter = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box sx={classes.root}>
      <Box sx={classes.logoContainer}>
        <Logo />
      </Box>
      <Box sx={classes.navContainer}>
        {!isMobile &&
          NavData.map(({ id, title, link }, index) => (
            <Link
              key={index}
              variant={"body1"}
              href={link ?? `#${id}`}
              target={link ? "_blank" : "_self"}
            >
              {title}
            </Link>
          ))}
        <Box sx={classes.iconsContainer}>
          <IconButton
            size={"small"}
            onClick={() => window.open(SocialNetworksLinks.tg, "_blank")}
          >
            <TGLogo />
          </IconButton>
          <IconButton
            size={"small"}
            onClick={() => window.open(SocialNetworksLinks.x, "_blank")}
          >
            <XLogo />
          </IconButton>
          <IconButton
            size={"small"}
            onClick={() => window.open(SocialNetworksLinks.web, "_blank")}
          >
            <WebLogo />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default MainFooter;
