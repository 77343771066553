import {
  useAccount,
  useBalance,
  useContractReads,
  useContractWrite,
} from "wagmi";

import { StakePoolAbi } from "@src/constants/abis";
import { Narrow } from "viem";
import { useCallback } from "react";
import { useFactoryRatio } from "./factory";

export enum PoolState {
  Cooldown,
  Staking,
  Withdraw,
  Mature,
}

interface Token {
  symbol: string;
  address: string;
  decimals: number;
  formatted: string;
  value: bigint;
}

interface StakeState {
  stakedTotal: bigint;
  stakedBalance: bigint;
  withdrawnEarly: bigint;
  userStakedBalance: bigint;
  ddfBalance: bigint;
}

interface RewardState {
  rewardBalance: bigint;
  rewardsTotal: bigint;
  earlyWithdrawReward: bigint;
}

interface Pool {
  name: string;
  state: PoolState;
  ddfReturnRatio: string;
  rewardOfUser: bigint;
  period: string;

  stakingDuration: string;
  withdrawDuration: string;

  stakingToken: Token;
  rewardToken: Token;

  apy: number;

  stakingStarts: Date;
  stakingStartsWhen: string;
  stakingEnds: Date;
  stakingEndsWhen: string;
  withdrawStarts: Date;
  withdrawStartsWhen: string;
  withdrawEnds: Date;
  withdrawEndsWhen: string;

  stakingCap: bigint;
  stakingRemaining: bigint;

  stakeState: StakeState;
  rewardState: RewardState;
}

const getDuration = (ending: Date, starting: Date) => {
  const diff = ending.getTime() - starting.getTime();
  const minutes = diff / 1000 / 60;
  if (minutes < 60) {
    return `${minutes.toFixed(0)} minutes`;
  }
  const hours = minutes / 60;
  if (hours <= 24) {
    return `${hours.toFixed(0)} hours`;
  }
  const days = hours / 24;
  if (days <= 31) {
    return `${days.toFixed(0)} days`;
  }
  const months = days / 30;
  return `${months.toFixed(0)} months`;
  // if (months < 12) {
  //   return `${months.toFixed(0)} months`;
  // }
  // const years = months / 12;
  // return `${years.toFixed(0)} years`;
};

export const getWhen = (time: Date, endingWhen: boolean = false) => {
  const now = new Date();
  if (now < time) {
    if (endingWhen) {
      return `Ends in ${getDuration(time, now)}`;
    }
    return `Starts in ${getDuration(time, now)}`;
  }

  if (endingWhen) {
    return `Ended ${getDuration(now, time)} ago`;
  }
  return `Started ${getDuration(now, time)} ago`;
};

const poolDefaults = (address: `0x${string}`) => ({
  abi: StakePoolAbi as Narrow<Array<any>>,
  address,
});

const useTokenBalance = (address: `0x${string}` | null) => {
  const { address: account } = useAccount();
  const balance = useBalance({
    token: (address as any) ?? ("" as any),
    address: account,
    watch: true,
  });

  if (balance.isLoading) {
    return { loading: true, token: null };
  }

  if (balance.error) {
    return { loading: false, token: null };
  }

  if (!balance.data) {
    return { loading: false, token: null };
  }

  return {
    loading: false,
    token: {
      symbol: balance.data.symbol,
      address: address,
      decimals: balance.data.decimals,
      formatted: balance.data.formatted,
      value: balance.data.value,
    },
  };
};

interface PoolResult {
  loading: boolean;
  pool: Pool | null;
}
export const usePoolByAddress = (address: string): PoolResult => {
  const { address: account } = useAccount();
  const { ratio, loading: ratioLoading } = useFactoryRatio(address);

  const reAddress = address as `0x${string}`;
  const defaults = poolDefaults(reAddress);

  const results = useContractReads({
    contracts: [
      {
        ...defaults,
        functionName: "name",
      },
      {
        ...defaults,
        functionName: "tokenAddress",
      },
      {
        ...defaults,
        functionName: "rewardTokenAddress",
      },
      {
        ...defaults,
        functionName: "stakingStarts",
      },
      {
        ...defaults,
        functionName: "stakingEnds",
      },
      {
        ...defaults,
        functionName: "withdrawStarts",
      },
      {
        ...defaults,
        functionName: "withdrawEnds",
      },
      {
        ...defaults,
        functionName: "stakingCap",
      },
      {
        ...defaults,
        functionName: "stakeState",
      },
      {
        ...defaults,
        functionName: "rewardState",
      },
      {
        ...defaults,
        functionName: "stakeOf",
        args: [account],
      },
      {
        ...defaults,
        functionName: "rewardOf",
        args: [account],
      },
    ],
    watch: true,
  }) as any;

  const tokenAddress = results?.data ? results.data[1]?.result ?? null : null;
  const stakeToken = useTokenBalance(tokenAddress);

  const rewardTokenAddress = results?.data
    ? results.data[2]?.result ?? null
    : null;
  const rewardToken = useTokenBalance(rewardTokenAddress);

  if (
    results.isLoading ||
    stakeToken.loading ||
    rewardToken.loading ||
    ratioLoading
  ) {
    return { loading: true, pool: null };
  }

  if (
    results.error ||
    stakeToken.token === null ||
    rewardToken.token === null ||
    ratio === null
  ) {
    console.log("no data");
    return { loading: false, pool: null };
  }

  if (!results.data || !results.data.length) {
    return { loading: false, pool: null };
  }

  // if (!results.data[7]?.result) {
  //   // first load cache issue fix
  //   return { loading: false, pool: null };
  // }

  const name = results.data[0].result as string;
  const stakingStarts = new Date(Number(results.data[3].result) * 1000);
  const stakingEnds = new Date(Number(results.data[4].result) * 1000);
  const withdrawStarts = new Date(Number(results.data[5].result) * 1000);
  const withdrawEnds = new Date(Number(results.data[6].result) * 1000);

  const stakingCap = BigInt(results.data[7]?.result ?? 0);
  let stakedTotal = BigInt(results.data[8]?.result[0] ?? 0);
  const stakedBalance = BigInt(results.data[8]?.result[1] ?? 0);
  const withdrawnEarly = BigInt(results.data[8]?.result[2] ?? 0);
  const userStakedBalance =
    BigInt(results.data[10].result) / BigInt(10 ** stakeToken.token.decimals);
  const ddfBalance =
    ratio !== BigInt(0)
      ? (userStakedBalance * ratio) / BigInt(10000)
      : BigInt(0);

  if (address.toLowerCase() === "0xcdcb04898d5d8ccda0fef503461883b2e29fa889") {
    stakedTotal = stakingCap;
  }

  const rewardBalance = BigInt(results.data[9]?.result[0]);
  const rewardsTotal = BigInt(results.data[9]?.result[1]);
  const earlyWithdrawReward = BigInt(results.data[9]?.result[2]);
  const rewardOfUser = BigInt(
    results.data[11]?.result - (results.data[11]?.result % BigInt(1e14)) ?? 0,
  );

  const returnRatio = `${ratio / BigInt(10000)}x`;

  let stakingRemaining = BigInt(0);

  if (stakingCap > BigInt(0)) {
    stakingRemaining = stakingCap - stakedTotal;
  }

  const state = (() => {
    const now = new Date();
    if (stakingStarts > now) {
      return PoolState.Cooldown;
    } else if (now < stakingStarts) {
      return PoolState.Staking;
    } else if (now < stakingEnds) {
      return PoolState.Staking;
    } else if (now < withdrawStarts) {
      return PoolState.Withdraw;
    } else if (now < withdrawEnds) {
      return PoolState.Withdraw;
    } else {
      return PoolState.Mature;
    }
  })();

  const period = (() => {
    if (state === PoolState.Cooldown) {
      return "Starting Soon";
    } else if (state === PoolState.Staking) {
      return "Jail Period";
    } else if (state === PoolState.Withdraw) {
      return "Bribe Period";
    } else {
      return "Release Period";
    }
  })();

  const stakingDuration = getDuration(stakingEnds, stakingStarts);
  const withdrawDuration = getDuration(withdrawEnds, withdrawStarts);

  return {
    loading: false,
    pool: {
      name,
      state,
      period,
      // todo: fix apy calculation.
      apy: 0,
      stakingToken: stakeToken.token as any,
      rewardToken: rewardToken.token as any,
      rewardOfUser,
      ddfReturnRatio: returnRatio,
      stakingDuration,
      withdrawDuration,
      stakingStarts,
      stakingStartsWhen: getWhen(stakingStarts),
      stakingEnds,
      stakingEndsWhen: getWhen(stakingEnds, true),
      withdrawStarts,
      withdrawStartsWhen: getWhen(withdrawStarts),
      withdrawEnds,
      withdrawEndsWhen: getWhen(withdrawEnds, true),
      stakingCap: stakingCap / BigInt(10 ** stakeToken.token.decimals),
      stakingRemaining:
        stakingRemaining / BigInt(10 ** stakeToken.token.decimals),
      stakeState: {
        stakedTotal,
        stakedBalance,
        withdrawnEarly,
        userStakedBalance,
        ddfBalance,
      },
      rewardState: {
        rewardBalance,
        rewardsTotal,
        earlyWithdrawReward,
      },
    },
  };
};

export const usePoolStake = (address: string, amount: bigint | undefined) => {
  const defaults = poolDefaults(address as `0x${string}`);
  const stake = useContractWrite({
    ...defaults,
    functionName: "stake",
    args: [amount],
  });

  const handleStake = useCallback(async () => {
    try {
      await stake.writeAsync();
    } catch (e) {
      console.error(e);
    }
  }, [stake]);

  return {
    stake: handleStake,
    loading: stake.isLoading,
  };
};

export const useExitStakePool = (address: string) => {
  const defaults = poolDefaults(address as `0x${string}`);
  const exit = useContractWrite({
    ...defaults,
    functionName: "exitPool",
  });

  const handleExit = useCallback(async () => {
    try {
      await exit.writeAsync();
    } catch (e) {
      console.error(e);
    }
  }, [exit]);

  return {
    exit: handleExit,
    loading: exit.isLoading,
  };
};

export const useClaimRewardPool = (address: string) => {
  const defaults = poolDefaults(address as `0x${string}`);
  const claim = useContractWrite({
    ...defaults,
    functionName: "claimReward",
  });

  const handleClaim = useCallback(async () => {
    try {
      await claim.writeAsync();
    } catch (e) {
      console.error(e);
    }
  }, [claim]);

  return {
    claim: handleClaim,
    loading: claim.isLoading,
  };
};
