import { Theme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { Palette } from "@mui/material/styles/createPalette";

const getTypography = (
  theme: Theme,
): TypographyOptions | ((palette: Palette) => TypographyOptions) => ({
  fontFamily: "'Montserrat', 'Open Sans', 'Oswald', sans-serif",
  h1: {
    fontSize: "78px",
    fontWeight: 400,
    lineHeight: "normal",
    [theme.breakpoints.down("sm")]: {
      fontSize: 54,
    },
  },
  h5: {
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "normal",
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  h6: {
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "normal",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  body1: {
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "normal",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
});

export default getTypography;
