export enum ChainId {
  Mainnet = 1,
  Sepolia = 11155111,
}

export const ADDRESS_ZERO = "0x0000000000000000000000000000000000000000";

export const StakingLibraryLink = {
  [ChainId.Mainnet]:
    "0x611FBdeaD231cCE1481565D6dD9EC9c8f4c052f9" as `0x${string}`,
  [ChainId.Sepolia]:
    "0xeDaF0F088e93663D158c661C49911643e80Ada76" as `0x${string}`,
};

export const StakePoolFactoryAddress = {
  [ChainId.Mainnet]:
    "0xe23d0e09a9490B0fE59BB2918f1a815358502da8" as `0x${string}`,
  [ChainId.Sepolia]:
    "0xA5A180Dd9CB7F69A12C9C67c9071A3BfD4892914" as `0x${string}`,
};

export const DDTokenAddress = {
  [ChainId.Mainnet]:
    "0x7d51888c5abb7cdfa9cdd6a50673c7f8afaccd7f" as `0x${string}`,
};
