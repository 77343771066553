import { Box } from "@mui/material";
import theme from "@styles/theme";
import MainTop from "@components/Main/MainTop";
import MainInfo from "@components/Main/MainInfo";
import MainPools from "@components/Main/MainPools";
import MainGuide from "@components/Main/MainGuide";
import MainFooter from "@components/Main/MainFooter";
import MainBgImages from "@components/Main/MainBgImages";
import MainVocabulary from "@components/Main/MainVocabulary";

const classes = {
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
  },
  container: {
    maxWidth: theme.breakpoints.values.lg,
    padding: theme.spacing(0, 4, 0, 4),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2, 0, 2),
    },
  },
};

const Main = () => {
  return (
    <Box sx={classes.root}>
      <MainTop />
      <Box sx={classes.container}>
        <MainInfo />
        <MainPools />
        <MainGuide />
        <MainFooter />
      </Box>
      <MainBgImages />
      <MainVocabulary />
    </Box>
  );
};

export default Main;
