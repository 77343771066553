import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { NavIds } from "@constants/NavIds";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import theme from "@styles/theme";

const classes = {
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: 5,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
  },
  details: {
    padding: theme.spacing(1, 4, 2),
  },
  summary: {
    padding: theme.spacing(0, 4),
  },
};

const MainGuide = () => {
  return (
    <Box sx={classes.root}>
      <Accordion id={NavIds.guide}>
        <AccordionSummary
          sx={classes.summary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant={"h5"}>
            How Access Cells & Yield Cells Work
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={classes.details}>
          <Typography variant={"body1"}>
            To participate in any of the cells, you will need to get Uniswap LP
            tokens for either DD/ETH (other pairs might be added later),
            depending on your chosen cell. The quickest way to get them:{" "}
            <a
              href={
                "https://app.uniswap.org/add/v2/0x7d51888C5ABb7CDfa9cdd6A50673c7F8afAccD7F/ETH"
              }
              target={"_blank"}
              rel={"noreferrer"}
            >
              click here.
            </a>
          </Typography>
          <Typography variant={"body1"}>
            The link will take you to the correct Uniswap pool. Provide
            liquidity to the pair, then come back here and stake it.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          sx={classes.summary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant={"h5"}>Yield Cells</Typography>
        </AccordionSummary>
        <AccordionDetails sx={classes.details}>
          <Typography variant={"body1"}>
            To participate in any of the cells, you will need to get Uniswap LP
            tokens for either DD/ETH (other pairs might be added later),
            depending on your chosen cell. The quickest way to get them:{" "}
            <a
              href={
                "https://app.uniswap.org/add/v2/0x7d51888C5ABb7CDfa9cdd6A50673c7F8afAccD7F/ETH"
              }
              target={"_blank"}
              rel={"noreferrer"}
            >
              click here.
            </a>
          </Typography>
          <Typography variant={"body1"}>
            As soon as the arrest period has finished, you start earing debts as
            bribes.
          </Typography>
          <Typography variant={"body1"}>
            There is two type of bribes:
          </Typography>
          <ol>
            <li>Ongoing bribes</li>
            <li>Closing bribes</li>
          </ol>
          <Typography variant={"body1"}>
            You can collect the ongoing bribes anytime during the Bribe Duration
            and as often as you want.
          </Typography>
          <Typography variant={"body1"}>
            You can collect the closing bribes at the end of the Bribe Duration
            when you leave the cell (released).
          </Typography>
          <Typography variant={"body1"}>
            Also, you can exit the cell any time.
          </Typography>
          <Typography variant={"body1"}>
            But, if you leave the cell during the Bribe Period, you will miss
            the closing bribes.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          sx={classes.summary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant={"h5"}>Access Cells</Typography>
        </AccordionSummary>
        <AccordionDetails sx={classes.details}>
          <Typography variant={"body1"}>
            The Access Cells work the same like the Bribe Cells, but you get
            also DDF (DuckDAO Farm token) into your wallet for the time you are
            participating in the cell. DDF is not transferable and will be
            removed from your wallet when you exit the cell.
          </Typography>
          <Typography variant={"body1"}>
            DDF will give you access to the DuckDAO community Inner Circle and
            Platforms.
          </Typography>
          <Typography variant={"body1"} style={{ paddingTop: "20px" }}>
            Context: To join the DuckDAO Inner Circle groups, you have two
            possibilities now. The already known one with staking $DD on{" "}
            <a
              href={"https://access.duckdao.io"}
              target={"_blank"}
              rel={"noreferrer"}
            >
              access.duckdao.io
            </a>{" "}
            for DDA (DuckDAO Access token), or NOW by providing liquidity to
            Uniswap in the ETH/DD pair.
          </Typography>
          <Typography variant={"body1"} style={{ paddingTop: "20px" }}>
            How it works:
          </Typography>
          <Typography variant={"body1"}>
            Check the{" "}
            <a
              href={
                "https://docs.google.com/document/d/1SdWruKv_Q1YnvP7HwO1Pfx9TcEfbHoGFOsXkeBn6uPM/edit?usp=sharing"
              }
              target={"_blank"}
              rel={"noreferrer"}
            >
              Guide
            </a>{" "}
            Here.
          </Typography>
          <Typography
            variant={"body1"}
            fontWeight="700"
            style={{ paddingTop: "20px" }}
          >
            Note: Bribe Cells provide you with higher APY, but do not give you
            any $DDF tokens, so they cannot be used to gain entry to the Inner
            Circle.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion id={NavIds.aboutUs}>
        <AccordionSummary
          sx={classes.summary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant={"h5"}>About Duck Prison</Typography>
        </AccordionSummary>
        <AccordionDetails sx={classes.details}>
          <Typography variant={"body1"}>
            The Duck Prison is a major part of the DuckDAO community,
            facilitating the creation of $DDF (our non-transferable access
            token), providing rewards for supporters, and generating stable
            liquidity for our tokens.
          </Typography>
          <Typography variant={"body1"} style={{ paddingTop: "20px" }}>
            DuckDAO is a decentralized community with a vested interest in
            providing access to early stage projects across all web3 verticals.
          </Typography>
          <Typography variant={"body1"} style={{ paddingTop: "20px" }}>
            If you need access to the old V2 Farm, you can find it{" "}
            <a
              href={"https://old.farm.duckdao.io/"}
              target={"_blank"}
              rel={"noreferrer"}
            >
              here.
            </a>
          </Typography>
          <Typography variant={"body1"} style={{ paddingTop: "20px" }}>
            Disclaimer: The current prison is in early beta, and is subject to
            future changes, including but not limited to: UI, UX and other
            features that may be added or removed.
          </Typography>
          <Typography variant={"body1"}>
            Learn more about DuckDAO at{" "}
            <a
              href={"https://duckdao.io/"}
              target={"_blank"}
              rel={"noreferrer"}
            >
              duckdao.io.
            </a>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default MainGuide;
