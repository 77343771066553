import { useAccount, useBalance, useChainId, useContractRead } from "wagmi";
import { readContract } from "@wagmi/core";
import { formatEther } from "viem";

import {
  ADDRESS_ZERO,
  ChainId,
  DDTokenAddress,
  StakePoolFactoryAddress,
} from "@src/constants/Address";
import { StakePoolFactoryAbi } from "@src/constants/abis";
import CommonConst from "@src/constants/Common";
import { useEffect, useState } from "react";

interface DDFHoldersResult {
  loading: boolean;
  holders: string[];
}

// todo: use subgraph
export const useDDFHolders = (): DDFHoldersResult => {
  const [holders] = useState<string[]>(Array.from({ length: 55 }));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHolders = async () => {
      try {
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    fetchHolders();
  }, []);

  return { loading, holders };
};

interface DDPriceFetchResult {
  loading: boolean;
  price: number | null;
}
export const useDDPrice = (): DDPriceFetchResult => {
  const [price, setPrice] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await fetch(CommonConst.ddPriceUrl);
        const data = await response.json();
        setPrice(data[DDTokenAddress[ChainId.Mainnet]].usd);
      } catch (e) {
        console.error(e);
        setPrice(0);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchPrice();
  }, []);

  return { loading, price };
};

const factoryDefaults = (chainId: number) => ({
  abi: StakePoolFactoryAbi,
  address: StakePoolFactoryAddress[chainId as ChainId],
});

interface FactoryBalanceResult {
  loading: boolean;
  token: {
    decimals: number;
    symbol: string;
    formatted: string;
    value: bigint;
  } | null;
}
export const useFactoryBalance = (): FactoryBalanceResult => {
  const { address } = useAccount();
  const chainId = useChainId();

  const balance = useBalance({
    address,
    token: StakePoolFactoryAddress[chainId as ChainId],
    watch: true,
  });

  const supply = useContractRead<
    typeof StakePoolFactoryAbi,
    "totalSupply",
    bigint
  >({
    ...factoryDefaults(chainId),
    functionName: "totalSupply",
    watch: true,
  });

  if (balance.isLoading) {
    return { loading: true, token: null };
  }

  if (balance.error || supply.error) {
    return { loading: false, token: null };
  }

  if (!balance.data) {
    return { loading: false, token: null };
  }

  return {
    loading: false,
    token: {
      ...balance.data,
      formatted: formatEther(
        balance.data.value - (balance.data.value % BigInt(1e14)),
      ),
    } as FactoryBalanceResult["token"],
  };
};

interface FactoryStatsResult {
  loading: boolean;
  totalPools: string | null;
  totalDDF: string | null;
  ddPrice: number | null;
  holders: string[];
}
export const useFactoryStats = (): FactoryStatsResult => {
  const chainId = useChainId();

  const { loading: priceLoading, price: ddPrice } = useDDPrice();
  const { holders } = useDDFHolders();
  const totalPools = useContractRead<
    typeof StakePoolFactoryAbi,
    "allPoolsLength",
    bigint
  >({
    ...factoryDefaults(chainId),
    functionName: "allPoolsLength",
    watch: true,
  });

  const totalDDF = useContractRead<
    typeof StakePoolFactoryAbi,
    "totalSupply",
    bigint
  >({
    ...factoryDefaults(chainId),
    functionName: "totalSupply",
    watch: true,
  });

  if (totalPools.isLoading || totalDDF.isLoading || priceLoading) {
    return {
      loading: true,
      totalPools: null,
      totalDDF: null,
      ddPrice: null,
      holders: [],
    };
  }

  if (totalPools.error || totalDDF.error) {
    return {
      loading: false,
      totalPools: null,
      totalDDF: null,
      ddPrice: null,
      holders: [],
    };
  }

  return {
    loading: false,
    totalPools: (totalPools?.data ?? BigInt(0)).toString(),
    totalDDF: formatEther(totalDDF?.data ?? BigInt(0)),
    ddPrice,
    holders,
  };
};

interface FactoryPoolsResult {
  loading: boolean;
  normal: string[];
  access: string[];
}
export const useFactoryPools = (
  index: number,
  length: number,
): FactoryPoolsResult => {
  const [loading, setLoading] = useState(true);
  const [normal, setNormal] = useState<string[]>([]);
  const [access, setAccess] = useState<string[]>([]);
  const chainId = useChainId();

  const result = useContractRead<
    typeof StakePoolFactoryAbi,
    "getPools",
    string[]
  >({
    ...factoryDefaults(chainId),
    functionName: "getPools",
    args: [index, length],
  });

  useEffect(() => {
    if (result.data) {
      const all = result.data
        .filter((pool) => pool !== ADDRESS_ZERO)
        .reduce(
          async (acc, cur) => {
            const curAccum = await acc;
            const ratio = await readContract({
              ...factoryDefaults(chainId),
              functionName: "ratios",
              args: [cur],
            });

            if (ratio === BigInt(0)) {
              return {
                ...curAccum,
                normal: [...curAccum.normal, cur],
              };
            }

            return {
              ...curAccum,
              access: [...curAccum.access, cur],
            };
          },
          Promise.resolve({ normal: [], access: [] } as any),
        );

      all.then((acc) => {
        setNormal(acc.normal);
        setAccess(acc.access);
        setLoading(false);
      });
    }
  }, [result.data, chainId]);

  return { loading, normal, access };
};

interface FactoryRatioResult {
  loading: boolean;
  ratio: bigint | null;
}

export const useFactoryRatio = (pool: string): FactoryRatioResult => {
  const chainId = useChainId();

  const result = useContractRead<typeof StakePoolFactoryAbi, "ratios", bigint>({
    ...factoryDefaults(chainId),
    functionName: "ratios",
    args: [pool],
    watch: true,
  });

  if (result.isLoading) {
    return { loading: true, ratio: null };
  }

  if (result.error) {
    return { loading: false, ratio: null };
  }

  return {
    loading: false,
    ratio: result?.data ?? null,
  };
};
