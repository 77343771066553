import { Box, Fab, IconButton, Typography } from "@mui/material";
import theme from "@styles/theme";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import Draggable from "react-draggable";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const classes = {
  sidebar: {
    backgroundColor: theme.palette.background.paper,
    position: "fixed",
    height: "auto",
    width: "400px",
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    gap: 4,
    justifyContent: "start",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    color: theme.palette.background.default,
    zIndex: 100,
    transition: "1s ease-in-out",
    textWrap: "nowrap",
    borderRadius: theme.spacing(4, 0, 0, 4),
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      height: "100vh",
      width: "100vw",
      borderRadius: 0,
      padding: theme.spacing(2),
    },
  },
  sidebarHide: {
    width: 0,
    paddingX: 0,
    [theme.breakpoints.down("sm")]: {
      width: 0,
      paddingX: 0,
    },
  },
  titleContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contentContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: 1,
  },

  // sideBtn: {
  //     position: 'fixed',
  //     height: '70vh',
  //     width: '32px',
  //     right: 0,
  //     top: '50%',
  //     transform: 'translateY(-50%)',
  //     zIndex: 99,
  //     borderRadius: theme.spacing(4, 0, 0, 4)
  // },
  // btnText: {
  //     transform: 'rotate(270deg)',
  //     textTransform: 'uppercase'
  // },

  fab: {
    position: "fixed",
    right: "4%",
    bottom: "4%",
    zIndex: 99,
  },

  a: {
    gridColumn: "span 2",
  },
  divider: {
    width: "100%",
    gridColumn: "span 2",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.background.default,
  },
};

const MainVocabulary = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [dragInfo, setDragInfo] = useState<
    { x: number; y: number; time: number } | undefined
  >();

  const handleDragStart = (x: number, y: number) => {
    setDragInfo({
      x,
      y,
      time: Date.now(),
    });
  };

  const handleDragStop = (x: number, y: number) => {
    if (!dragInfo) return;
    const change: { x: number; y: number; time: number } = {
      x: Math.abs(x - dragInfo.x),
      y: Math.abs(y - dragInfo.y),
      time: Date.now() - dragInfo.time,
    };
    if (change.x + change.y <= 10 && change.time < 300) {
      setOpen(!open);
    }
  };

  return (
    <>
      <Draggable
        onStart={(e, data) => handleDragStart(data.x, data.y)}
        onStop={(e, data) => handleDragStop(data.x, data.y)}
      >
        <Fab sx={classes.fab} color="secondary" variant={"extended"}>
          <Typography variant={"h5"} fontWeight={700}>
            Vocabulary
          </Typography>
        </Fab>
      </Draggable>

      {/*<Button variant={'contained'} color={"secondary"} onClick={() => setOpen(true)} sx={classes.sideBtn}>*/}
      {/*    <Typography sx={classes.btnText} variant={'h5'} fontWeight={700}>Vocabulary</Typography>*/}
      {/*</Button>*/}

      <Box sx={[classes.sidebar, !open && classes.sidebarHide]}>
        <Box sx={classes.titleContainer}>
          <Typography variant={"h6"} fontWeight={700}>
            Vocabulary
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={classes.contentContainer}>
          <Typography variant={"body1"} fontWeight={700}>
            Prison
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Farm
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Cell
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Farming Pool
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Inmates
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Farmers
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Jailing Period
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Staking Period
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Bribe Period
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Yield Period
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Bribe Duration
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Yield Duration
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Jail Token
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Staking Token
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Bribe Token
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Reward Token
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Jailed
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Staked
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Your Bribes
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Your Rewards
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Arrest
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Stake
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Collect Debt
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Harvest
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Release
          </Typography>
          <Typography variant={"body1"} fontWeight={700}>
            Unstake
          </Typography>
          <Box sx={classes.divider} />
          <Box
            component={"a"}
            sx={classes.a}
            target={"_blank"}
            rel={"noreferrer"}
            href="https://app.uniswap.org/add/v2/0x7d51888C5ABb7CDfa9cdd6A50673c7F8afAccD7F/ETH"
          >
            <Typography variant={"body1"} fontWeight={700}>
              Uniswap Liquidity Pool DD/ETH <OpenInNewIcon />
            </Typography>
          </Box>
          <Box
            component={"a"}
            sx={classes.a}
            target={"_blank"}
            rel={"noreferrer"}
            href="https://app.uniswap.org/add/v2/0x7d51888C5ABb7CDfa9cdd6A50673c7F8afAccD7F/0xdAC17F958D2ee523a2206206994597C13D831ec7"
          >
            <Typography variant={"body1"} fontWeight={700}>
              Uniswap Liquidity Pool DD/USDT <OpenInNewIcon />
            </Typography>
          </Box>
          <Box
            component={"a"}
            sx={classes.a}
            target={"_blank"}
            rel={"noreferrer"}
            href="https://etherscan.io/token/0x7d51888C5ABb7CDfa9cdd6A50673c7F8afAccD7F"
          >
            <Typography variant={"body1"} fontWeight={700}>
              DD Token Contract <OpenInNewIcon />
            </Typography>
          </Box>
          <Box
            component={"a"}
            sx={classes.a}
            target={"_blank"}
            rel={"noreferrer"}
            href="https://www.dextools.io/app/en/ether/pair-explorer/0xe8c19b0281580b0d2468741eb9f845c949393d76"
          >
            <Typography variant={"body1"} fontWeight={700}>
              Dextools <OpenInNewIcon />
            </Typography>
          </Box>
          <Box
            component={"a"}
            sx={classes.a}
            target={"_blank"}
            rel={"noreferrer"}
            href="https://www.coingecko.com/en/coins/duckdao"
          >
            <Typography variant={"body1"} fontWeight={700}>
              Coingecko <OpenInNewIcon />
            </Typography>
          </Box>
          <Box
            component={"a"}
            sx={classes.a}
            target={"_blank"}
            rel={"noreferrer"}
            href="https://coinmarketcap.com/currencies/duckdao/"
          >
            <Typography variant={"body1"} fontWeight={700}>
              CoinMarketCap <OpenInNewIcon />
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MainVocabulary;
