import { Box, Link, useMediaQuery } from "@mui/material";
import { ReactComponent as Logo } from "@assets/svg/logoYellow.svg";
import { NavData } from "@constants/NavIds";
import ConnectButton from "@components/ConnectButton/ConnectButton";
import { useFactoryBalance } from "@src/hooks/factory";
import theme from "@styles/theme";

const classes = {
  root: {
    width: "100%",
    height: 100,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    px: 2,
    gap: 1,
    maxWidth: theme.breakpoints.values.lg,
  },
  logoContainer: {
    width: 50,
    height: 50,
  },
  navContainer: {
    display: "flex",
    alignItems: "center",
    gap: 3,
  },
};

const MainHeader = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const balance = useFactoryBalance();

  return (
    <Box sx={classes.root}>
      <Box sx={classes.logoContainer}>
        <Logo />
      </Box>
      <Box sx={classes.navContainer}>
        {!isMobile &&
          NavData.map(({ id, title, link }, index) => (
            <Link
              key={index}
              variant={"body1"}
              href={link ?? `#${id}`}
              target={link ? "_blank" : "_self"}
            >
              {title}
            </Link>
          ))}
        <ConnectButton balance={balance.token?.formatted} />
      </Box>
    </Box>
  );
};

export default MainHeader;
