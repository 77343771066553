import { Box, Typography } from "@mui/material";
import theme from "@styles/theme";
import { useFactoryStats } from "@src/hooks/factory";

const classes = {
  root: {
    width: "100%",
    marginTop: 5,
    display: "flex",
    flexDirection: "column",
    gap: 3.75,
  },
  infoRowContainer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    [theme.breakpoints.down("md")]: {
      gridTemplateRows: "repeat(2, 1fr)",
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateRows: "repeat(4, 1fr)",
      gridTemplateColumns: "none",
    },
  },
  infoContainer: {
    width: "100%",
    height: 233,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    alignSelf: "baseline",
    [theme.breakpoints.down("md")]: {
      height: 150,
    },
    [theme.breakpoints.down("sm")]: {
      height: 100,
    },
  },
  poolsContainer: {
    width: "100%",
    display: "flex",
    gap: 6.25,
    [theme.breakpoints.down("md")]: {
      gap: 2,
    },
  },
  card: {
    width: "100%",
    minHeight: 330,
    display: "flex",
    flexDirection: "column",
    gap: 1.25,
    padding: theme.spacing(3.75, 6.25, 3.75, 6.25),
  },
  cardBottomContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "auto",
  },
};

const MainInfo = () => {
  const { loading, totalPools, ddPrice, holders } = useFactoryStats();

  // todo: create external data for this kind of information
  const totalDDFMembers = 1247; // DDA + DDF holders

  return (
    <Box sx={classes.root}>
      <Box sx={classes.infoRowContainer}>
        <Box sx={classes.infoContainer}>
          <Typography variant={"h5"}>
            {loading ? "..." : Number(totalPools).toLocaleString()}
          </Typography>
          <Typography variant={"h6"}>Total Jail Cells</Typography>
        </Box>
        <Box sx={classes.infoContainer}>
          <Typography variant={"h5"}>
            {loading ? "..." : holders.length.toLocaleString()}
          </Typography>
          <Typography variant={"h6"}>Total Inmates</Typography>
        </Box>
        <Box sx={classes.infoContainer}>
          <Typography variant={"h5"}>$ {ddPrice?.toLocaleString()}</Typography>
          <Typography variant={"h6"}>DD Price</Typography>
        </Box>
        <Box sx={classes.infoContainer}>
          <Typography variant={"h5"}>
            {totalDDFMembers.toLocaleString()}
          </Typography>
          <Typography variant={"h6"}>Inner Circle Members</Typography>
        </Box>
      </Box>
      {
        // <Box sx={classes.poolsContainer}>
        //   <Card sx={classes.card}>
        //     <Typography variant={"h5"}>Access Pools</Typography>
        //     <Typography variant={"body1"}>
        //       Stake DD-LP to receive yield and access to DuckStarter and the Inner
        //       Circle
        //     </Typography>
        //     <Typography variant={"body1"}>(DD/ETH or DD/USDT)</Typography>
        //     <Typography variant={"body1"}>
        //       Full access to tiers and membership
        //     </Typography>
        //     <Box sx={classes.cardBottomContainer}>
        //       <Typography variant={"h5"}>6-8% APY</Typography>
        //       <Link href={`#${NavIds.accessPools}`}>
        //         <Button variant={"contained"}>Stake</Button>
        //       </Link>
        //     </Box>
        //   </Card>
        //   <Card sx={classes.card}>
        //     <Typography variant={"h5"}>Yield Pools</Typography>
        //     <Typography variant={"body1"}>
        //       Stake $DD LP to receive only yield after the duration expires, 3 to
        //       12 months staking plans.
        //     </Typography>
        //     <Typography variant={"body1"}>(DD/ETH only)</Typography>
        //     <Typography variant={"body1"}>
        //       No access to tiers and membership
        //     </Typography>
        //     <Box sx={classes.cardBottomContainer}>
        //       <Typography variant={"h5"}>18-20% APY</Typography>
        //       <Link href={`#${NavIds.yieldPools}`}>
        //         <Button variant={"contained"}>Stake</Button>
        //       </Link>
        //     </Box>
        //   </Card>
        // </Box>
      }
    </Box>
  );
};

export default MainInfo;
