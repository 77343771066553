import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AppRoutes from "@constants/AppRoutes";
import Main from "@pages/Main";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={AppRoutes.main} element={<Main />} />
        <Route
          path={"*"}
          element={<Navigate replace={false} to={AppRoutes.main} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
