import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as TGLogo } from "@assets/svg/tgWhite.svg";
import { ReactComponent as XLogo } from "@assets/svg/xWhite.svg";
import { ReactComponent as WebLogo } from "@assets/svg/webWhite.svg";
import SocialNetworksLinks from "@constants/SocialNetworksLinks";
import MainHeader from "@components/Main/MainHeader";
import theme from "@styles/theme";

const classes = {
  root: {
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  contentContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 3.75,
    maxWidth: theme.breakpoints.values.lg,
    padding: theme.spacing(2),
    paddingBottom: 10,
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },

  overlay: {
    width: "100%",
    backgroundColor: "rgba(0,0,0,0.6)",
    backdropFilter: "blur(5px)",
    display: "flex",
    justifyContent: "center",
  },
  iconsContainer: {
    display: "flex",
    gap: 2,
  },
};

const MainTop = () => {
  return (
    <Box sx={classes.root}>
      <Box sx={classes.overlay}>
        <MainHeader />
      </Box>

      <Box sx={classes.overlay}>
        <Box sx={classes.contentContainer}>
          <Typography variant={"h1"}>
            DuckPrison ⛓️ <b>Early Access</b>
          </Typography>
          <Typography variant={"h5"}>
            Jail your $DD LP and make your tokens generate bribes 💸 for you.
          </Typography>
          <Box sx={classes.iconsContainer}>
            <IconButton
              size={"small"}
              onClick={() => window.open(SocialNetworksLinks.tg, "_blank")}
            >
              <TGLogo />
            </IconButton>
            <IconButton
              size={"small"}
              onClick={() => window.open(SocialNetworksLinks.x, "_blank")}
            >
              <XLogo />
            </IconButton>
            <IconButton
              size={"small"}
              onClick={() => window.open(SocialNetworksLinks.web, "_blank")}
            >
              <WebLogo />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MainTop;
