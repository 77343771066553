import { Box, Typography } from "@mui/material";
import { NavIds } from "@constants/NavIds";
import { useState } from "react";
import { useFactoryPools } from "@src/hooks/factory";
import styled, { keyframes } from "styled-components";
import { useAccount } from "wagmi";
import { ConnectText } from "../ConnectButton/ConnectButton";
import MainPool from "@components/Main/MainPool";
import theme from "@styles/theme";

const classes = {
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 5,
    marginTop: 5,
    [theme.breakpoints.down("sm")]: {
      gap: 2.5,
    },
  },
  poolsContainer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridAutoRows: "auto",
    gap: 2,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  loaderContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(180deg);
  }
`;

const SpinnerLoading = styled.div`
  width: 40px;
  height: 40px;
  border: 2px solid ${({ theme }) => theme.text1};
  border-radius: 50%;
  animation: ${spinner} 0.5s linear infinite;
`;

const pageLimit = 40;
const MainPools = () => {
  const { address } = useAccount();
  const [page] = useState(0);
  const { loading, normal, access } = useFactoryPools(page, pageLimit);

  const isEmpty = !loading && normal.length === 0 && access.length === 0;

  return (
    <Box sx={classes.root}>
      {isEmpty && (
        <Typography align="center" variant={"h4"}>
          No cells available yet.
        </Typography>
      )}
      {!isEmpty && (
        <>
          <Typography align="left" id={NavIds.accessPools} variant={"h5"}>
            Access Cells
          </Typography>
          {loading ? (
            <Box sx={classes.loaderContainer}>
              <SpinnerLoading />
            </Box>
          ) : (
            <Box sx={classes.poolsContainer}>
              {access?.map((pool, index) => (
                <MainPool
                  key={pool}
                  address={pool}
                  isHalfContainer={
                    access.length > 1 &&
                    !((index + 1) % 2 !== 0 && index + 1 === access.length)
                  }
                />
              ))}
            </Box>
          )}
          <Typography align="left" id={NavIds.accessPools} variant={"h5"}>
            Yield Cells
          </Typography>

          {loading ? (
            <Box sx={classes.loaderContainer}>
              <SpinnerLoading />
            </Box>
          ) : (
            <Box sx={classes.poolsContainer}>
              {normal?.map((pool, index) => (
                <MainPool
                  key={pool}
                  address={pool}
                  isHalfContainer={
                    normal.length > 1 &&
                    !((index + 1) % 2 !== 0 && index + 1 === normal.length)
                  }
                />
              ))}
            </Box>
          )}
        </>
      )}
      {!address && <ConnectText />}
    </Box>
  );
};

export default MainPools;
