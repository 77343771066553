import { createTheme, Theme } from "@mui/material/styles";
import getTypography from "@styles/typography";
import getOverridesComponent from "@styles/overridesComponent";

import { colors } from "@theme/colors";

const createCustomTheme = (): Theme => {
  const colorsObject = colors();

  const baseTheme = createTheme({
    spacing: (x: number) => `${x * 8}px`,
    palette: {
      background: {
        default: colorsObject.bg1,
        paper: colorsObject.primary2,
      },
      text: {
        primary: colorsObject.text1,
        secondary: colorsObject.bg1,
      },
      primary: {
        main: colorsObject.primary1,
      },
      secondary: {
        main: colorsObject.primary2,
      },
      info: {
        main: colorsObject.secondary4,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1024,
        lg: 1200,
        xl: 1680,
      },
    },
  });

  return createTheme({
    ...baseTheme,
    components: getOverridesComponent(baseTheme),
    typography: getTypography(baseTheme),
  });
};

export default createCustomTheme();
