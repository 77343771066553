import { createConfig, configureChains, mainnet, sepolia } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { getDefaultConfig } from "connectkit";

const { chains } = configureChains([mainnet, sepolia], [publicProvider()]);

const wagmiConfig = createConfig(
  getDefaultConfig({
    chains,

    infuraId:
      process.env.REACT_APP_INFURA_ID || "89eb3383f45b40a488bc8479d6b743bd",
    walletConnectProjectId:
      process.env.REACT_APP_WC_PROJECT_ID || "c30b687f1397af65fc2bb5eea7004a1b",

    appName: "DuckDAO Farm V3",
    appDescription: "DuckDAO Farming Application V3",
    appUrl: "https://farm.duckdao.io",
    appIcon: "https://farm.duckdao.io/logo.png",
  }),
);

export { chains, wagmiConfig };
