import React from "react";
import ReactDOM from "react-dom/client";
import App from "@src/App";
import reportWebVitals from "@src/reportWebVitals";
import { CssBaseline, ThemeProvider as MuiThemeProvider } from "@mui/material";
import theme from "@styles/theme";
import { SnackbarProvider } from "notistack";
import { wagmiConfig } from "@configs/wagmi";
import { WagmiConfig as WagmiProvider } from "wagmi";
import { ConnectKitProvider } from "connectkit";
import ThemeProvider from "@theme/provider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <MuiThemeProvider theme={theme}>
        <WagmiProvider config={wagmiConfig}>
          <ConnectKitProvider>
            <SnackbarProvider>
              <CssBaseline />
              <App />
            </SnackbarProvider>
          </ConnectKitProvider>
        </WagmiProvider>
      </MuiThemeProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
