import { Components, Theme } from "@mui/material/styles";
import { colors } from "@theme/colors";

const getOverridesComponent = (theme: Theme): Components => ({
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        scrollBehavior: "smooth",
      },
      body: {
        background: "linear-gradient(180deg, #000 34.65%, #0D0D0D 42.21%)",
      },
      a: {
        color: "inherit",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontSize: 18,
        fontWeight: 700,
        lineHeight: "normal",
        fontStyle: "normal",
        padding: theme.spacing(1, 2),
        borderRadius: 10,
        color: theme.palette.text.secondary,
        textTransform: "capitalize",
        [theme.breakpoints.down("md")]: {
          fontSize: 16,
          padding: theme.spacing(1, 1.5),
        },
      },
      containedSecondary: {
        borderRadius: 20,
      },
      containedInfo: {
        color: theme.palette.text.primary,
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        color: colors().bg1,
        "&:first-of-type": {
          borderTopLeftRadius: theme.spacing(2),
          borderTopRightRadius: theme.spacing(2),
        },
        "&:last-of-type": {
          borderBottomLeftRadius: theme.spacing(2),
          borderBottomRightRadius: theme.spacing(2),
        },
        "&.Mui-expanded": {
          boxShadow: "none",
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      sizeSmall: {
        padding: 0,
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: 20,
        color: theme.palette.text.secondary,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        border: "unset",
        height: "100%",
        top: 0,
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        color: theme.palette.common.black,
        height: "100%",
      },
      input: {
        height: "100%",
        boxSizing: "inherit",
        padding: `${theme.spacing(1.25, 3.75, 1.25, 3.75)}!important`,
        fontSize: "inherit",
        fontWeight: "inherit",
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: "none",
        color: "inherit",
      },
    },
  },
});

export default getOverridesComponent;
