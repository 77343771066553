import React, { FC, useState } from "react";
import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import theme from "@styles/theme";
import {
  PoolState,
  useClaimRewardPool,
  useExitStakePool,
  usePoolByAddress,
  usePoolStake,
} from "@hooks/pool";
import { ApprovalState, useApproval } from "@hooks/common";
import styled, { keyframes } from "styled-components";
import { mainBaseColor } from "@styles/colors";
import { formatEther, parseEther } from "viem";
import Apys from "@constants/Apy";
import { colors } from "@theme/colors";
import { ReactComponent as UniswapLogo } from "@assets/svg/uniswap.svg";
import UniswapLinks from "@constants/UniswapLinks";

const placeholdAnimation = keyframes`
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: calc(400px + 100%) 0;
  }
`;

const PoolPlaceholderLoader = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.bg2} 0,
    ${({ theme }) => theme.bg3} 50%,
    ${({ theme }) => theme.bg2} 100%
  );
  background-size: 400px;
  animation: ${placeholdAnimation} 2.5s linear infinite;
`;

const classes = {
  root: {
    width: "100%",
    padding: theme.spacing(4),
    borderRadius: 4,
    display: "flex",
    justifyContent: "space-between",
    gap: 2,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      padding: 2,
    },
  },
  halfContainer: {
    flexDirection: "column",
  },
  fullContainer: {
    gridColumn: "1 / span 2",
  },
  infoContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 1.25,
  },
  circle: {
    width: 24,
    height: 24,
    borderRadius: "50%",
    display: "inline-block",
  },
  inputContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: 1,
  },
  textFiledContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    alignItems: "start",
    marginTop: "auto",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
  },
  textField: {
    width: "100%",
    borderRadius: 4,
  },

  btnContainer: {
    display: "flex",
    alignItems: "center",
  },
  startBtn: {
    flexShrink: 0,
    borderRadius: theme.spacing(4, 0, 0, 4),
  },
  middleBtn: {
    flexShrink: 0,
    borderRadius: 0,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderRightStyle: "solid",
    borderLeftStyle: "solid",
    borderRightColor: mainBaseColor,
    borderLeftColor: mainBaseColor,
  },
  lastBtn: {
    flexShrink: 0,
    borderRadius: theme.spacing(0, 4, 4, 0),
  },
};

const ProgressContainer = styled.div`
  display: flex;
  position: relative;
  width: 40%;
  height: 16px;
  align-items: center;
`;

const ProgressBar = styled.progress`
  width: 100%;
  height: 16px;
  border-radius: 40%;
  appearance: none;
  background: ${({ theme }) => theme.bg1};
  &::-webkit-progress-bar {
    background: ${({ theme }) => theme.bg1};
    border-radius: 4px;
  }
  &::-webkit-progress-value {
    background: ${({ theme }) => theme.green1};
    border-radius: 4px;
  }
  &::-moz-progress-bar {
    background: ${({ theme }) => theme.green1};
    border-radius: 4px;
  }
`;

const ProgressPercent = styled.div<{ progress?: number }>`
  position: absolute;
  right: 4px;
  color: ${({ progress }) => ((progress ?? 0) > 80 ? "#000" : "#fff")};
  font-size: 12px;
`;

const ContainerText = styled(Typography)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  address: string;
  isHalfContainer?: boolean;
};

const MainPool: FC<Props> = ({ address, isHalfContainer }) => {
  const [amount, setAmount] = useState("");

  const { loading, pool } = usePoolByAddress(address);
  const { state, approve } = useApproval(
    parseEther(amount ?? "0"),
    address,
    pool?.stakingToken.address,
  );
  const { loading: stakeLoading, stake } = usePoolStake(
    address,
    parseEther(amount ?? "0"),
  );
  const { loading: exitLoading, exit } = useExitStakePool(address);
  const { loading: claimLoading, claim } = useClaimRewardPool(address);
  const balance = pool?.stakingToken.value ?? BigInt(0);

  const apys = Apys[address.toLowerCase()] ?? {
    ongoing: 0,
    closing: 0,
    total: 0,
  };

  const dotColor = () => {
    const c = colors();
    if (pool?.state == null) return c.bg1;

    switch (pool.state) {
      case PoolState.Staking:
        return c.green1;
      case PoolState.Cooldown:
        return c.yellow1;
      case PoolState.Withdraw:
        return c.blue1;
      case PoolState.Mature:
        return c.red1;
    }
  };

  const percentUsed = () => {
    if (pool?.state == null) return 0;

    return pool.stakingCap > BigInt(0)
      ? Number(
          (Number(pool.stakingCap - pool.stakingRemaining) /
            Number(pool.stakingCap)) *
            100,
        )
      : 0;
  };

  const progress = percentUsed();

  return (
    <Card
      sx={{
        ...classes.root,
        ...(!isHalfContainer && classes.fullContainer),
      }}
    >
      {loading ? (
        <PoolPlaceholderLoader />
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Box>
            <Typography variant={"h5"}>
              <Box sx={classes.circle} bgcolor={dotColor()} />
              {pool?.name}
              {pool?.name.toLowerCase().includes("eth") && (
                <Box
                  component={"a"}
                  sx={{
                    display: "inline-block",
                    width: 39,
                    height: 39,
                    verticalAlign: "bottom",
                  }}
                  href={UniswapLinks["eth"] || "#"}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  <IconButton
                    sx={{
                      width: 39,
                      height: 39,
                      padding: 0,
                      display: "inline-block",
                      verticalAlign: "bottom",
                    }}
                  >
                    <UniswapLogo width={39} height={39} />
                  </IconButton>
                </Box>
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: isHalfContainer ? "column" : "row",
              justifyContent: "space-between",
              gap: 1,
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
              },
            }}
          >
            <Box sx={classes.infoContainer}>
              <Typography variant={"h6"} fontWeight={700}>
                Symbol: {pool?.stakingToken.symbol}/{pool?.rewardToken.symbol}
              </Typography>
              <Typography variant={"body1"}>
                Current Period: {pool?.period}
              </Typography>
              <Typography variant={"body1"}>
                Arrest Duration: {pool?.stakingDuration} (
                {pool?.state === PoolState.Cooldown
                  ? pool?.stakingStartsWhen
                  : pool?.stakingEndsWhen}
                )
              </Typography>
              <Typography variant={"body1"}>
                Bribe Duration: {pool?.withdrawDuration} (
                {pool?.state === 0
                  ? pool?.withdrawStartsWhen
                  : pool?.withdrawEndsWhen}
                )
              </Typography>
              <ContainerText variant={"body1"}>
                Max Jailing:{" "}
                {pool?.stakingCap
                  ? pool.stakingCap > BigInt(0)
                    ? `${pool?.stakingRemaining}/${pool?.stakingCap} ${pool?.stakingToken.symbol}`
                    : ""
                  : "UNCAPPED"}
                {pool?.stakingCap ? (
                  pool.stakingCap > BigInt(0) && (
                    <ProgressContainer>
                      <ProgressBar value={progress} max={100} />
                      <ProgressPercent progress={progress}>
                        {progress}%
                      </ProgressPercent>
                    </ProgressContainer>
                  )
                ) : (
                  <Typography variant={"body1"}>
                    Unlimited Jailing 🚀
                  </Typography>
                )}
              </ContainerText>
              <Typography variant={"body1"}>
                Jail Token: {pool?.stakingToken.symbol}{" "}
                {pool?.stakingToken.address.substring(0, 6)}...
                {pool?.stakingToken.address.substring(
                  pool?.stakingToken.address.length - 6,
                )}
              </Typography>
              <Typography variant={"body1"}>
                Bribe Token: {pool?.rewardToken.symbol}{" "}
                {pool?.rewardToken.address.substring(0, 6)}...
                {pool?.rewardToken.address.substring(
                  pool?.rewardToken.address.length - 6,
                )}
              </Typography>
            </Box>

            <Box sx={classes.inputContainer}>
              <Typography variant={"body1"}>
                Jailed ⛓️:{" "}
                {pool?.stakeState?.userStakedBalance.toLocaleString()}{" "}
                {pool?.stakingToken.symbol}
                {pool?.ddfReturnRatio === "0x"
                  ? ""
                  : ` (${pool?.ddfReturnRatio} DDF)`}
              </Typography>
              <Typography variant={"body1"}>
                Your Bribes 💸: {formatEther(pool?.rewardOfUser ?? BigInt(0))}{" "}
                {pool?.rewardToken.symbol}
              </Typography>
              <Typography variant={"body1"}>
                Ongoing APY: {apys.ongoing}%
              </Typography>
              <Typography variant={"body1"}>
                Closing APY: {apys.closing}%
              </Typography>
              <Typography variant={"body1"}>
                Total APY: {apys.total}%
              </Typography>
              <Box sx={classes.textFiledContainer}>
                <TextField
                  sx={classes.textField}
                  type="number"
                  value={amount}
                  placeholder={`balance: ${formatEther(balance || BigInt(0))}`}
                  onChange={(e) => setAmount(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={"end"}>
                        <Button
                          onClick={() =>
                            setAmount(formatEther(balance || BigInt(0)))
                          }
                        >
                          Max
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box sx={classes.btnContainer}>
                  {state !== ApprovalState.Approved &&
                    state !== ApprovalState.Unknown && (
                      <Button
                        sx={classes.startBtn}
                        variant={"contained"}
                        onClick={() => approve()}
                      >
                        {state === ApprovalState.Approving
                          ? "Approving..."
                          : "Approve"}
                      </Button>
                    )}
                  {(state === ApprovalState.Approved ||
                    state === ApprovalState.Unknown) && (
                    <Button
                      sx={classes.startBtn}
                      variant={"contained"}
                      disabled={
                        stakeLoading ||
                        state !== ApprovalState.Approved ||
                        pool?.state !== PoolState.Staking ||
                        parseEther(amount ?? "0") === BigInt(0) ||
                        parseEther(amount ?? "0") > balance
                      }
                      onClick={() => stake()}
                    >
                      {stakeLoading ? "Arresting..." : "Arrest"}
                    </Button>
                  )}
                  <Button
                    sx={classes.middleBtn}
                    variant={"contained"}
                    disabled={
                      claimLoading ||
                      pool?.state === PoolState.Staking ||
                      (pool?.rewardOfUser ?? BigInt(0)) <= BigInt(0)
                    }
                    onClick={() => claim()}
                  >
                    {claimLoading ? "Collecting..." : "Collect Debt"}
                  </Button>
                  <Button
                    sx={classes.lastBtn}
                    variant={"contained"}
                    disabled={
                      exitLoading ||
                      pool?.state === PoolState.Staking ||
                      parseInt(
                        pool?.stakeState?.userStakedBalance.toString() ?? "0",
                      ) <= 0
                    }
                    onClick={() => exit()}
                  >
                    {exitLoading ? "Releasing..." : "Release"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Card>
  );
};

export default MainPool;
