import { Box } from "@mui/material";
import bottomBg from "@assets/png/footerBackground.png";
import bg from "@assets/webp/background.webp";
import theme from "@styles/theme";

const classes = {
  topBgImg: {
    position: "absolute",
    backgroundPosition: "center",
    top: 0,
    right: 0,
    height: "100vh",
    width: "100vw",
    objectFit: "cover",
    zIndex: -1,
  },
  bottomBgImg: {
    height: 300,
    position: "absolute",
    bottom: 50,
    right: 200,
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      left: 0,
      right: 0,
      margin: "auto",
    },
  },
  footerColor: {
    position: "absolute",
    bottom: 0,
    zIndex: -1,
    height: 150,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
};

const MainBgImages = () => {
  return (
    <>
      <Box
        sx={classes.topBgImg}
        component={"img"}
        src={bg}
        alt={"top background image"}
      />
      <Box
        sx={classes.bottomBgImg}
        component={"img"}
        src={bottomBg}
        alt={"bottom background image"}
      />
      <Box sx={classes.footerColor} />
    </>
  );
};

export default MainBgImages;
