import { useCallback, useState } from "react";
import { useAccount, useContractRead, useContractWrite } from "wagmi";

import { ERC20Abi } from "@src/constants/abis";

export enum ApprovalState {
  Unknown,
  Approved,
  Approving,
  NotApproved,
}

const tokenDefaults = (token: string | undefined) => ({
  abi: ERC20Abi,
  address: token as `0x${string}`,
});

interface ApprovalResult {
  state: ApprovalState;
  approve: () => void;
}
export const useApproval = (
  amount: bigint | undefined,
  spender: string | undefined,
  token: string | undefined,
): ApprovalResult => {
  const { address } = useAccount();
  const [approving, setApproving] = useState(false);

  const allowance = useContractRead<typeof ERC20Abi, "allowance", bigint>({
    ...tokenDefaults(token),
    functionName: "allowance",
    args: [address, spender],
    watch: true,
  });

  const approve = useContractWrite({
    ...tokenDefaults(token),
    functionName: "approve",
    args: [spender, amount],
  });

  const handleApprove = useCallback(async () => {
    try {
      setApproving(true);
      await approve.writeAsync();
    } catch (e) {
      console.error(e);
    } finally {
      setApproving(false);
    }
  }, [approve]);

  if (approve.isLoading || approving) {
    return {
      state: ApprovalState.Approving,
      approve: async () => {},
    };
  }

  if (allowance.isLoading) {
    return {
      state: ApprovalState.Unknown,
      approve: async () => {},
    };
  }

  if (allowance.error) {
    console.error(allowance.error);
    return {
      state: ApprovalState.Unknown,
      approve: async () => {},
    };
  }

  if (allowance.data === undefined || amount === undefined) {
    return {
      state: ApprovalState.Unknown,
      approve: async () => {},
    };
  }

  if (amount === BigInt(0)) {
    return {
      state: ApprovalState.Unknown,
      approve: async () => {},
    };
  }

  if (allowance.data < amount) {
    return {
      state: ApprovalState.NotApproved,
      approve: handleApprove,
    };
  }

  return {
    state: ApprovalState.Approved,
    approve: async () => {},
  };
};
