import { Button, Typography } from "@mui/material";
import { ConnectKitButton } from "connectkit";
import styled from "styled-components";

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 38px;
  font-size: 18px;
`;

const BalanceContainer = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  padding: 0.5rem 1rem;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  height: 100%;
`;

const ConnectAccountText = styled(Typography)`
  color: ${({ theme }) => theme.primary1};
  text-align: center;

  span {
    cursor: pointer;
    text-decoration: underline;
  }
`;

interface ConnectButtonProps {
  balance?: string;
}

const ConnectButton = ({ balance }: ConnectButtonProps) => {
  return (
    <ConnectKitButton.Custom>
      {({ isConnected, show, truncatedAddress, ensName }) => (
        <ButtonContainer>
          {balance && <BalanceContainer>{balance} $DDF</BalanceContainer>}
          <Button
            variant={"contained"}
            color={"secondary"}
            sx={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            onClick={show}
          >
            {isConnected ? ensName ?? truncatedAddress : "Connect"}
          </Button>
        </ButtonContainer>
      )}
    </ConnectKitButton.Custom>
  );
};

export const ConnectText = () => {
  return (
    <ConnectKitButton.Custom>
      {({ show }) => (
        <ConnectAccountText variant={"h4"}>
          <span onClick={show}>Connect</span> your wallet to access pools
        </ConnectAccountText>
      )}
    </ConnectKitButton.Custom>
  );
};

export default ConnectButton;
