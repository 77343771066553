export default {
  "0x2259c8b79bd0d618d21201b32d48b8a9ade60a9e": {
    ongoing: 14,
    closing: 10,
    total: 24,
  },
  "0x25544aac6e9dc8a89c91528fbe3b9e9e15a6ecc3": {
    ongoing: 6,
    closing: 4,
    total: 10,
  },
  "0xcdcb04898d5d8ccda0fef503461883b2e29fa889": {
    ongoing: 4,
    closing: 2,
    total: 6,
  },
  "0x67ece41ff3bb2942d90840ae20f2b34404c3fee6": {
    ongoing: 6,
    closing: 2,
    total: 8,
  },
  "0xb0419b6970deaf727f6c715a99613d76ddcd7e36": {
    ongoing: 6,
    closing: 2,
    total: 8,
  },
} as Record<string, { ongoing: number; closing: number; total: number }>;
